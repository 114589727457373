import React, { useState } from 'react'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';


function ReUploadDocs() {
  const [adhar_no, setAdhar] = useState();
  const [pan_no, setPan] = useState();

  const [customer_img, setCustImg] = useState(null);
  const [adhar_img_f, setAdharFsImg] = useState(null);
  const [adhar_img_b, setAdharBsImg] = useState(null);
  const [pan_img, setPanImg] = useState(null);
  const [cc_img, setCcImg] = useState(null);

  const [bank_name, setBankName] = useState('Axis Bank');
  const [bank_acc_no, setBankAccNo] = useState();
  const [bank_ifsc_no, setBankIfscNo] = useState();
  const [bank_branch, setBankBranch] = useState();


  const [adharError, setAdharError] = useState("");
  const [panError, setPanError] = useState("");

  const [bankNameError, setBankNamError] = useState();
  const [bankAccNoError, setBankAccNoError] = useState();
  const [bankIfscNoError, setBankIfscNoError] = useState();
  const [bankBranchError, setBankBranchError] = useState();

  const [customerImgError, setCustImgError] = useState();
  const [adharImgFsError, setAdharImgFsError] = useState();
  const [adharImgBsError, setAdharImgBsError] = useState();
  const [panImgError, setPanImgError] = useState();
  const [ccImgError, setCcImgError] = useState();

  const [agreement_doc, setAgreement] = useState(null);
  const [agreementError, setAgreementError] = useState();

  const bankNames = [
    'Axis Bank',
    'Bandhan Bank',
    'Bank of Baroda',
    'Bank of India',
    'Bank of Maharashtra',
    'CSB Bank',
    'Canara Bank',
    'Central Bank of India',
    'City Union Bank',
    'DCB Bank',
    'Dhanlaxmi Bank',
    'Federal Bank',
    'HDFC Bank',
    'ICICI Bank',
    'IDBI Bank',
    'IDFC FIRST Bank',
    'Indian Bank',
    'Indian Overseas Bank',
    'IndusInd Bank',
    'J&K Bank',
    'Karnataka Bank',
    'Karur Vysya Bank',
    'Kotak Mahindra Bank',
    'Nainital Bank',
    'Punjab & Sind Bank',
    'Punjab National Bank',
    'RBL Bank',
    'South Indian Bank',
    'State Bank of India',
    'Tamilnad Mercantile Bank',
    'UCO Bank',
    'Union Bank of India',
    'YES Bank'
  ];

  const handleAdharFsImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setAdharFsImg(selectedFile);
      setAdharImgFsError('');
    } else {
      setAdharImgFsError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setAdharFsImg(null);
    }
  };

  const handleAdharBsImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setAdharBsImg(selectedFile);
      setAdharImgBsError('');
    } else {
      setAdharImgBsError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setAdharBsImg(null);
    }
  };

  const handlePanImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setPanImg(selectedFile);
      setPanImgError('');
    } else {
      setPanImgError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setPanImg(null);
    }
  };

  const handleCcImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setCcImg(selectedFile);
      setCcImgError('');
    } else {
      setCcImgError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setCcImg(null);
    }
  };

  const handleCustImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setCustImg(selectedFile);
      setCustImgError('');
    } else {
      setCustImgError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setCustImg(null);
    }
  };

  const handleAgreementChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'application/pdf')) {
      setAgreement(selectedFile);
      setAgreementError('');
    } else {
      setAgreementError('Invalid file type. Please upload only pdf format file.');
      setAgreement(null);
    }
  };


  const getHeaders = () => {
    return {
      'Content-Type': 'multipart/form-data',
      'token': localStorage.getItem('token'),
    };
  }

  const handleCustImgSubmit = (e) => {
    setCustImgError('')
    e.preventDefault();
    let isValidCred = true;

    if (customer_img === null) {
      setCustImgError("Please select customer passport size photo!")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = getHeaders()

      const formData = new FormData();
      formData.append('customer_img', customer_img);
      axios.put(apiRoutes.customer + '/uploadcp', formData, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              localStorage.setItem('userpic', response.data.data.new_img);
              alert("Your passport size photo uploaded successfully!")
            } else {
              setCustImgError(response.data.msg)
            }
          }
        })
        .catch(err => console.error(err))
    }
  }

  const handleAdharSubmit = (e) => {
    e.preventDefault();
    setAdharError('')
    setAdharImgFsError('')
    setAdharImgBsError('')
    let isValidCred = true;

    if (adhar_no === undefined || "" === adhar_no) {
      setAdharError("Please enter a adhar no")
      isValidCred = false;
      return
    }
    if (adhar_img_f === null) {
      setAdharImgFsError("Please select valid adhar image")
      isValidCred = false;
      return
    }
    if (adhar_img_b === null) {
      setAdharImgBsError("Please select valid adhar image")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = getHeaders()
      const formData = new FormData();
      formData.append('adhar_no', adhar_no);
      formData.append('adhar_img_f', adhar_img_f);
      formData.append('adhar_img_b', adhar_img_b);

      axios.put(apiRoutes.customer + '/uploadadhar', formData, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              alert("Your adhar card details uploaded successfully!")
            } else {
              setAdharError(response.data.msg)
            }
          }
        })
        .catch(err => console.error(err))
    }
  }

  const handlePanSubmit = (e) => {
    e.preventDefault();
    let isValidCred = true;
    setPanError('')
    setPanImgError('')

    if (pan_no === undefined || "" === pan_no) {
      setPanError("Please enter a pan card number")
      isValidCred = false;
      return
    }

    if (pan_img === null) {
      setPanImgError("Please select valid pan image")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = getHeaders()
      const formData = new FormData();
      formData.append('pan_no', pan_no);
      formData.append('pan_img', pan_img);

      axios.put(apiRoutes.customer + '/uploadpan', formData, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              alert("Your pan card details uploaded successfully!")
            } else {
              setPanError(response.data.msg)
            }
          }
        })
        .catch(err => console.error(err))
    }
  }
  const handleBankSubmit = (e) => {
    e.preventDefault();
    let isValidCred = true;
    setBankNamError('')
    setBankAccNoError('')
    setBankIfscNoError('')
    setBankBranchError('')
    setCcImgError('')

    if (bank_name === undefined || "" === bank_name) {
      setBankNamError("Please select your bank from list")
      isValidCred = false;
      return
    }
    if (bank_acc_no === undefined || "" === bank_acc_no) {
      setBankAccNoError("Please enter a bank account number")
      isValidCred = false;
      return
    }
    if (bank_ifsc_no === undefined || "" === bank_ifsc_no) {
      setBankIfscNoError("Please enter bank ifsc number")
      isValidCred = false;
      return
    }
    if (bank_branch === undefined || "" === bank_branch) {
      setBankBranchError("Please enter bank branch")
      isValidCred = false;
      return
    }
    if (cc_img === null) {
      setCcImgError("Please select valid cancelled cheque/passbook image")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = getHeaders()
      const formData = new FormData();
      formData.append('bank_name', bank_name);
      formData.append('bank_acc_no', bank_acc_no);
      formData.append('bank_ifsc_no', bank_ifsc_no);
      formData.append('bank_branch', bank_branch);
      formData.append('cc_img', cc_img);

      axios.put(apiRoutes.customer + '/uploadbankdetails', formData, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              alert("Your bank details uploaded successfully!")
            } else {
              setBankNamError(response.data.msg)
            }
          }
        })
        .catch(err => console.error(err))
    }
  }

  const handleAgreementSubmit = (e) => {
    e.preventDefault();
    let isValidCred = true;
    setAgreementError('')

    if (agreement_doc === null) {
      setAgreementError("Please select valid agreement pdf.")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = getHeaders()
      const formData = new FormData();
      formData.append('agreement_doc', agreement_doc);

      axios.put(apiRoutes.customer + '/uploadagreement', formData, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              alert("Your agreement details uploaded successfully!")
            } else {
              setAgreementError(response.data.msg)
            }
          }
        })
        .catch(err => console.error(err))
    }
  }

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Upload your documents!
            </h1>
            <br /><label className="errorLabel">Note: If you re-upload KYC documents (Adhar/Pan/Bank details), your account will be 'Inactive'. After your document verification is completed, then it will be 'Active' again!</label>
          </div>
        </div>
      </section>
      <hr />
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Upload passport size photo!
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleCustImgSubmit}>
              <div>
                <label htmlFor="customer_img" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your passport size photo</label>
                <input type="file" name="customer_img" id="customer_img" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => handleCustImgChange(e)} />
                <label className="errorLabel">{customerImgError}</label>
              </div>
              <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Upload Passport Size Photo</button>
            </form>
            <br /><label className="errorLabel">Note: Only image formats are allowed and the allowed formats are jpeg, jpg and png.</label>
          </div>
        </div>
      </section>

      <hr />

      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Upload adhar card details!
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleAdharSubmit}>
              <div>
                <label htmlFor="adhar_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your adhar card number</label>
                <input type="text" name="adhar_no" id="adhar_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => setAdhar(e.target.value)} />
                <label className="errorLabel">{adharError}</label>
              </div>
              <div>
                <label htmlFor="adhar_img_f" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select adhar card front side</label>
                <input type="file" name="adhar_img_f" id="adhar_img_f" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => handleAdharFsImgChange(e)} />
                <label className="errorLabel">{adharImgFsError}</label>
              </div>
              <div>
                <label htmlFor="adhar_img_b" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select adhar card back side</label>
                <input type="file" name="adhar_img_b" id="adhar_img_b" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => handleAdharBsImgChange(e)} />
                <label className="errorLabel">{adharImgBsError}</label>
              </div>
              <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Upload Adhar Details</button>
            </form>
            <br /><label className="errorLabel">Note: Only image formats are allowed and the allowed formats are jpeg, jpg and png.</label>
          </div>
        </div>
      </section>

      <hr />

      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Upload pan card details!
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handlePanSubmit}>
              <div>
                <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your pan number</label>
                <input type="text" name="pan_no" id="pan_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => setPan(e.target.value)} />
                <label className="errorLabel">{panError}</label>
              </div>
              <div>
                <label htmlFor="pan_img" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select pan card</label>
                <input type="file" name="pan_img" id="pan_img" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => handlePanImgChange(e)} />
                <label className="errorLabel">{panImgError}</label>
              </div>
              <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Upload Pan Card Details</button>
            </form>
            <br /><label className="errorLabel">Note: Only image formats are allowed and the allowed formats are jpeg, jpg and png.</label>
          </div>
        </div>
      </section>

      <hr />

      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Upload bank account details!
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleBankSubmit}>
              <div>
                <label htmlFor="bank_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your bank name</label>
                <select name="bank_name" id="bank_name" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                  onChange={(e) => setBankName(e.target.value)} >
                  {bankNames.map((bank) => (
                    <option key={bank} value={bank}>
                      {bank}
                    </option>
                  ))}
                </select>
                <label className="errorLabel">{bankNameError}</label>
              </div>
              <div>
                <label htmlFor="bank_acc_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your bank account number</label>
                <input type="number" name="bank_acc_no" id="bank_acc_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => setBankAccNo(e.target.value)} />
                <label className="errorLabel">{bankAccNoError}</label>
              </div>
              <div>
                <label htmlFor="bank_ifsc_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your bank IFSC number</label>
                <input type="text" name="bank_ifsc_no" id="bank_ifsc_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => setBankIfscNo(e.target.value)} />
                <label className="errorLabel">{bankIfscNoError}</label>
              </div>
              <div>
                <label htmlFor="bank_branch" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your bank branch</label>
                <input type="text" name="bank_branch" id="bank_branch" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => setBankBranch(e.target.value)} />
                <label className="errorLabel">{bankBranchError}</label>
              </div>
              <div>
                <label htmlFor="cc_img" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select cancelled cheque/passbook photo</label>
                <input type="file" name="cc_img" id="cc_img" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => handleCcImgChange(e)} />
                <label className="errorLabel">{ccImgError}</label>
              </div>
              <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Upload Bank Details</button>
            </form>
            <br /><label className="errorLabel">Note: Only image formats are allowed and the allowed formats are jpeg, jpg and png.</label>
          </div>
        </div>
      </section>

      <hr />

      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Upload self signed agreement!
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleAgreementSubmit}>

              <div>
                <label htmlFor="agreement_doc" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your self signed agreement</label>
                <input type="file" name="agreement_doc" id="agreement_doc" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => handleAgreementChange(e)} />
                <label className="errorLabel">{agreementError}</label>
              </div>
              <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Upload Agreement</button>
            </form>
            <br /><label className="errorLabel">Note: Only pdf format are allowed.</label>
          </div>
        </div>
      </section>
    </>
  )
}

export default ReUploadDocs