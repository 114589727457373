import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function AddInvestmentRequest() {
  const [investment_date, setInvestmentDate] = useState();
  const [amount, setAmount] = useState();
  const [type, setType] = useState("IMPS");
  const [transaction_id, setTransactionId] = useState();
  const [upi_id, setUpiId] = useState("NA");

  const options = [
    { value: 'IMPS', label: 'IMPS' },
    { value: 'UPI', label: 'UPI' },
    { value: 'RTGS', label: 'RTGS' }
  ];

  const [investmentDateError, setInvestmentDateError] = useState();
  const [amountError, setAmountError] = useState();
  const [typeError, setTypeError] = useState();
  const [transactionIdError, setTransactionIdError] = useState();
  const [upiIdError, setUpiIdError] = useState("");

  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setInvestmentDateError('')
    setAmountError('')
    setTypeError('')
    setTransactionIdError('')
    setUpiIdError('')
    e.preventDefault();
    let isValidCred = true;

    if (amount === undefined || "" === amount || amount <= 0) {
      setAmountError("Please enter valid amount")
      isValidCred = false;
      return
    }
    if (type === undefined || "" === type) {
      setTypeError("Please select valid payment type")
      isValidCred = false;
      return
    }

    if (transaction_id === undefined || transaction_id === false) {
      setTransactionIdError("Please enter valid Transaction ID or Transaction Number.")
      isValidCred = false;
      return
    }

    if (type && type === 'UPI') {
      if (upi_id === undefined || upi_id === false || `${upi_id}`.trim() === "" || `${upi_id}`.trim() === "NA") {
        setUpiIdError("Please enter valid bank UPI ID Or type NA if not applicable.")
        isValidCred = false;
        return
      }
    }

    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };
      axios.post(apiRoutes.investment + '/add', {
        'amount': amount,
        'investment_date': investment_date,
        'type': type,
        'transaction_id': transaction_id,
        'upi_id': upi_id,
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              navigate('/investment-history')
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }


  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Add Investment Transaction Details
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="investment_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter investment transaction date*</label>
                  <input type="date" name="investment_date" id="investment_date" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setInvestmentDate(e.target.value)} />
                  <label className="errorLabel">{investmentDateError}</label>
                </div>
                <div>
                  <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter investment amount*</label>
                  <input type="number" name="amount" id="amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setAmount(e.target.value)} />
                  <label className="errorLabel">{amountError}</label>
                </div>
                <div>
                  <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select payment type*</label>
                  <select name="type" id="type" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                    onChange={(e) => setType(e.target.value)} >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <label className="errorLabel">{typeError}</label>
                </div>
                <div>
                  <label htmlFor="transaction_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter Transaction ID/Number*</label>
                  <input type="text" name="transaction_id" id="transaction_id" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setTransactionId(e.target.value)} />
                  <label className="errorLabel">{transactionIdError}</label>
                </div>

                <div>
                  <label htmlFor="upi_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter UPI ID (If payment type is UPI)</label>
                  <input type="text" name="upi_id" id="upi_id" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="abc@upi"
                    required=""
                    onChange={(e) => setUpiId(e.target.value)} />
                  <label className="errorLabel">{upiIdError}</label>
                </div>

                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit Request</button>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddInvestmentRequest