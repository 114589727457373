import React, { useState } from 'react'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function ChangePassword() {
  const [old_password, setOldPassword] = useState();
  const [new_password, setNewPassword] = useState();
  const [re_password, setRePassword] = useState();
  const [password_updated, setPasswordUpdated] = useState("");


  const [oldPasswordError, setOldPasswordError] = useState();
  const [newPasswordError, setNewPasswordError] = useState();
  const [rePasswordError, setRePasswordError] = useState();

  const [apiError, setApiError] = useState("");

  const handleSubmit = (e) => {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    setOldPasswordError('')
    setNewPasswordError('')
    setRePasswordError('')
    setApiError('')

    e.preventDefault();
    let isValidCred = true;
    if (old_password === undefined || "" === old_password || `${old_password}`.length < 8) {
      setOldPasswordError("Please enter old password")
      isValidCred = false;
      return
    }
    if (new_password === undefined || "" === new_password || !regex.test(new_password)) {
      setNewPasswordError("Please enter valid new password")
      isValidCred = false;
      return
    }
    if (re_password === undefined || "" === re_password || re_password !== new_password || !regex.test(re_password)) {
      setRePasswordError("Please re-enter valid new password. Re-entered password and new password must be same")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };
      axios.put(apiRoutes.customer + '/change-password', {
        'old_password': old_password,
        'new_password': new_password,
        're_password': re_password
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              setPasswordUpdated(response.data.msg);
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }


  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Change Password
              </h1>
              <p className="errorLabel">{apiError}</p>
              {password_updated !== "" ? (
                <h3 style={{ 'color': 'green' }}>{password_updated}</h3>
              ) : (
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="old_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter old password*</label>
                    <input type="password" name="old_password" id="old_password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder=""
                      required=""
                      onChange={(e) => setOldPassword(e.target.value)} />
                    <label className="errorLabel">{oldPasswordError}</label>
                  </div>
                  <div>
                    <label htmlFor="new_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter new password*</label>
                    <input type="password" name="new_password" id="new_password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder=""
                      required=""
                      onChange={(e) => setNewPassword(e.target.value)} />
                    <label className="errorLabel">{newPasswordError}</label>
                  </div>
                  <div>
                    <label htmlFor="re_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Re-enter new password*</label>
                    <input type="password" name="re_password" id="re_password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder=""
                      required=""
                      onChange={(e) => setRePassword(e.target.value)} />
                    <label className="errorLabel">{rePasswordError}</label>
                  </div>

                  <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Change password</button>
                  <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Note: Password must meet following guidelines.</label>
                  <ol style={{ 'margin': '10px', 'padding': '10px' }} type="1">
                    <li>1] At least one lowercase alphabet i.e. [a-z]</li>
                    <li>2] At least one uppercase alphabet i.e. [A-Z]</li>
                    <li>3] At least one Numeric digit i.e. [0-9]</li>
                    <li>4] At least one special character i.e. ['@', '$', '.', '#', '!', '%', '*', '?', '&', '^']</li>
                    <li>5] Also, the total length of password between 8 to 15 character</li>
                  </ol>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ChangePassword