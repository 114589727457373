import {
	HiOutlineViewGrid,
	HiOutlineUsers,
	HiOutlineQuestionMarkCircle,
	HiOutlineCog,
	HiOutlineDocumentReport,
	HiOutlineDownload,
	HiOutlineSupport,
	HiOutlineCash,
	HiOutlineUpload,
} from 'react-icons/hi'

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'dashboard',
		label: 'Dashboard',
		path: '/dashboard',
		icon: <HiOutlineViewGrid />
	},
	{
		key: 'investments',
		label: 'Investments history',
		path: '/investment-history',
		icon: <HiOutlineCash />
	},
	{
		key: 'withdrawal',
		label: 'Withdrawal history',
		path: '/withdrawal-history',
		icon: <HiOutlineCash />
	},
	{
		key: 'referral',
		label: 'Referral code',
		path: '/referral-code',
		icon: <HiOutlineUsers />
	},
	{
		key: 'reports',
		label: 'Reports',
		path: '/reports',
		icon: <HiOutlineDocumentReport />
	},
	{
		key: 'uploads',
		label: 'Upload Documents',
		path: '/upload',
		icon: <HiOutlineUpload />
	},
	// {
	// 	key: 'downloads',
	// 	label: 'Download Documents',
	// 	path: '/documents',
	// 	icon: <HiOutlineDownload />
	// },
	{
		key: 'support',
		label: 'Customer Support',
		path: '/support',
		icon: <HiOutlineSupport />
	}
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	{
		key: 'Terms and Conditions',
		label: 'Terms and Conditions',
		path: '/terms-and-conditions',
		icon: <HiOutlineCog />
	},
	{
		key: 'Privacy Policy',
		label: 'Privacy Policy',
		path: '/privacy-policy',
		icon: <HiOutlineQuestionMarkCircle />
	}
	// {
	// 	key: 'Profile',
	// 	label: 'Account Details',
	// 	path: '/profile',
	// 	icon: <HiOutlineQuestionMarkCircle />
	// }
]
