import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import logo from '../../assets/app-logo.jpg';
import apiRoutes from '../../components/common/apiRoutes.json';


function Signup() {
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [adhar_no, setAdhar] = useState();
  const [pan_no, setPan] = useState();
  // const [profession, setProfesion] = useState();
  // const [address, setAddress] = useState();
  // const [city, setCity] = useState();
  // const [state, setState] = useState();
  // const [pincode, setPincode] = useState();
  const [atcnp, setAtcnp] = useState();

  const [customer_img, setCustImg] = useState(null);
  const [adhar_img_f, setAdharFsImg] = useState(null);
  const [adhar_img_b, setAdharBsImg] = useState(null);
  const [pan_img, setPanImg] = useState(null);
  const [cc_img, setCcImg] = useState(null);

  const [bank_name, setBankName] = useState('Axis Bank');
  const [bank_acc_no, setBankAccNo] = useState();
  const [bank_ifsc_no, setBankIfscNo] = useState();
  const [bank_branch, setBankBranch] = useState();
  const [referral_code, setReferralCode] = useState("");

  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [adharError, setAdharError] = useState("");
  const [panError, setPanError] = useState("");
  // const [professionError, setProfesionError] = useState("");
  // const [addressError, setAddressError] = useState("");
  // const [cityError, setCityError] = useState("");
  // const [stateError, setStateError] = useState("");
  // const [pincodeError, setPincodeError] = useState("");
  const [atcnpError, setAtcnpError] = useState("");

  const [bankNameError, setBankNamError] = useState();
  const [bankAccNoError, setBankAccNoError] = useState();
  const [bankIfscNoError, setBankIfscNoError] = useState();
  const [bankBranchError, setBankBranchError] = useState();
  const [referralCodeError, setReferralCodeError] = useState();

  const [customerImgError, setCustImgError] = useState();
  const [adharImgFsError, setAdharImgFsError] = useState();
  const [adharImgBsError, setAdharImgBsError] = useState();
  const [panImgError, setPanImgError] = useState();
  const [ccImgError, setCcImgError] = useState();

  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let rc = params.get("rc")
    if (rc) { setReferralCode(rc); }
  }, [])

  const bankNames = [
    'Axis Bank',
    'Bandhan Bank',
    'Bank of Baroda',
    'Bank of India',
    'Bank of Maharashtra',
    'CSB Bank',
    'Canara Bank',
    'Central Bank of India',
    'City Union Bank',
    'DCB Bank',
    'Dhanlaxmi Bank',
    'Federal Bank',
    'HDFC Bank',
    'ICICI Bank',
    'IDBI Bank',
    'IDFC FIRST Bank',
    'Indian Bank',
    'Indian Overseas Bank',
    'IndusInd Bank',
    'J&K Bank',
    'Karnataka Bank',
    'Karur Vysya Bank',
    'Kotak Mahindra Bank',
    'Nainital Bank',
    'Punjab & Sind Bank',
    'Punjab National Bank',
    'RBL Bank',
    'South Indian Bank',
    'State Bank of India',
    'Tamilnad Mercantile Bank',
    'UCO Bank',
    'Union Bank of India',
    'YES Bank'
  ];

  const handleAdharFsImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setAdharFsImg(selectedFile);
      setAdharImgFsError('');
      // || selectedFile.type === 'application/pdf'
    } else {
      setAdharImgFsError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setAdharFsImg(null);
    }
  };

  const handleAdharBsImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setAdharBsImg(selectedFile);
      setAdharImgBsError('');
    } else {
      setAdharImgBsError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setAdharBsImg(null);
    }
  };

  const handlePanImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setPanImg(selectedFile);
      setPanImgError('');
    } else {
      setPanImgError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setPanImg(null);
    }
  };

  const handleCcImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setCcImg(selectedFile);
      setCcImgError('');
    } else {
      setCcImgError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setCcImg(null);
    }
  };

  const handleCustImgChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'image/jpg')) {
      setCustImg(selectedFile);
      setCustImgError('');
    } else {
      setCustImgError('Invalid file type. Please upload only images in the following format: jpeg, jpg, png.');
      setCustImg(null);
    }
  };

  const handleSubmit = (e) => {
    setNameError('')
    setMobileError('')
    setAtcnpError('')
    setApiError('')
    setEmailError('')
    setAdharError('')
    setPanError('')
    // setProfesionError('')
    // setAddressError('')
    // setCityError('')
    // setStateError('')
    // setPincodeError('')
    setBankNamError('')
    setBankAccNoError('')
    setBankIfscNoError('')
    setBankBranchError('')
    setReferralCodeError('')

    setCcImgError('')
    setAdharImgFsError('')
    setAdharImgBsError('')
    setPanImgError('')
    setCustImgError('')

    e.preventDefault();
    let isValidCred = true;

    if (name === undefined || "" === name) {
      setNameError("Please enter your name")
      isValidCred = false;
      return
    }
    if (mobile === undefined || "" === mobile) {
      setMobileError("Please enter a mobile")
      isValidCred = false;
      return
    }
    if (email === undefined || "" === email) {
      setMobileError("Please enter a email address")
      isValidCred = false;
      return
    }

    if (atcnp === undefined || atcnp === false || atcnp === 'on') {
      setAtcnpError("Please click bok to accept that you have read all terms and condition.")
      isValidCred = false;
      return
    }

    if (adhar_no === undefined || "" === adhar_no) {
      setAdharError("Please enter a adhar no")
      isValidCred = false;
      return
    }
    if (pan_no === undefined || "" === pan_no) {
      setPanError("Please enter a pan card")
      isValidCred = false;
      return
    }
    if (bank_acc_no === undefined || "" === bank_acc_no) {
      setBankAccNoError("Please enter a bank account number")
      isValidCred = false;
      return
    }
    if (bank_ifsc_no === undefined || "" === bank_ifsc_no) {
      setBankIfscNoError("Please enter bank ifsc number")
      isValidCred = false;
      return
    }
    if (bank_branch === undefined || "" === bank_branch) {
      setBankBranchError("Please enter bank branch")
      isValidCred = false;
      return
    }
    if (referral_code !== "" && `${referral_code}`.trim().length !== 10) {
      setReferralCodeError("Please enter valid referral code")
      isValidCred = false;
      return
    }
    if (customer_img === null) {
      setCustImgError("Please select customer image")
      isValidCred = false;
      return
    }
    if (adhar_img_f === null) {
      setAdharImgFsError("Please select valid adhar image")
      isValidCred = false;
      return
    }
    if (adhar_img_b === null) {
      setAdharImgBsError("Please select valid adhar image")
      isValidCred = false;
      return
    }
    if (pan_img === null) {
      setPanImgError("Please select valid pan image")
      isValidCred = false;
      return
    }
    if (cc_img === null) {
      setCcImgError("Please select valid cancelled cheque/passbook image")
      isValidCred = false;
      return
    }

    if (isValidCred) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('mobile', mobile);
      formData.append('email', email);
      formData.append('adhar_no', adhar_no);
      formData.append('pan_no', pan_no);
      formData.append('bank_name', bank_name);
      formData.append('bank_acc_no', bank_acc_no);
      formData.append('bank_ifsc_no', bank_ifsc_no);
      formData.append('bank_branch', bank_branch);
      formData.append('referral_code', referral_code);
      formData.append('atcnp', atcnp);

      formData.append('customer_img', customer_img);
      formData.append('adhar_img_f', adhar_img_f);
      formData.append('adhar_img_b', adhar_img_b);
      formData.append('pan_img', pan_img);
      formData.append('cc_img', cc_img);

      axios.post(apiRoutes.signup, formData)
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              alert("You have registerd successfully on portal. Temporary password sent on your registerd email address.")
              navigate('/login')
            } else {
              setApiError(response.data.msg)
              // if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              //   if (response.data?.missingFields.includes('password')) {
              //     setPasswordError("The password is invalid!")
              //   }
              //   if (response.data?.missingFields.includes('username')) {
              //     setUsernameError("The username is invalid!")
              //   }
              // }
            }
          }
        })
        .catch(err => console.error(err))
    }
  }

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center">
          <img className="w-100 h-40 mr-2" src={logo} alt='logo' />

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign up to Fulfill your Dreams!
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your full name</label>
                  <input type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Name as per adhar card"
                    required=""
                    onChange={(e) => setName(e.target.value)} />
                  <label className="errorLabel">{nameError}</label>
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your email</label>
                  <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                    onChange={(e) => setEmail(e.target.value)} />
                  <label className="errorLabel">{emailError}</label>
                </div>
                <div>
                  <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your mobile number</label>
                  <input type="number" name="mobile" id="mobile" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="9000000001"
                    required=""
                    onChange={(e) => setMobile(e.target.value)} />
                  <label className="errorLabel">{mobileError}</label>
                </div>
                <div>
                  <label htmlFor="customer_img" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload your passport size photo</label>
                  <input type="file" name="customer_img" id="customer_img" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => handleCustImgChange(e)} />
                  <label className="errorLabel">{customerImgError}</label>
                </div>
                <div>
                  <label htmlFor="adhar_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your adhar card number</label>
                  <input type="text" name="adhar_no" id="adhar_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setAdhar(e.target.value)} />
                  <label className="errorLabel">{adharError}</label>
                </div>
                <div>
                  <label htmlFor="adhar_img_f" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload adhar card front side</label>
                  <input type="file" name="adhar_img_f" id="adhar_img_f" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => handleAdharFsImgChange(e)} />
                  <label className="errorLabel">{adharImgFsError}</label>
                </div>
                <div>
                  <label htmlFor="adhar_img_b" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload adhar card back side</label>
                  <input type="file" name="adhar_img_b" id="adhar_img_b" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => handleAdharBsImgChange(e)} />
                  <label className="errorLabel">{adharImgBsError}</label>
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your pan number</label>
                  <input type="text" name="pan_no" id="pan_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setPan(e.target.value)} />
                  <label className="errorLabel">{panError}</label>
                </div>
                <div>
                  <label htmlFor="pan_img" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload pan card</label>
                  <input type="file" name="pan_img" id="pan_img" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => handlePanImgChange(e)} />
                  <label className="errorLabel">{panImgError}</label>
                </div>
                <div>
                  <label htmlFor="bank_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select your bank name</label>
                  <select name="bank_name" id="bank_name" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                    onChange={(e) => setBankName(e.target.value)} >
                    {bankNames.map((bank) => (
                      <option key={bank} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </select>
                  <label className="errorLabel">{bankNameError}</label>
                </div>
                <div>
                  <label htmlFor="bank_acc_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your bank account number</label>
                  <input type="number" name="bank_acc_no" id="bank_acc_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setBankAccNo(e.target.value)} />
                  <label className="errorLabel">{bankAccNoError}</label>
                </div>
                <div>
                  <label htmlFor="bank_ifsc_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your bank IFSC number</label>
                  <input type="text" name="bank_ifsc_no" id="bank_ifsc_no" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setBankIfscNo(e.target.value)} />
                  <label className="errorLabel">{bankIfscNoError}</label>
                </div>
                <div>
                  <label htmlFor="bank_branch" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your bank branch</label>
                  <input type="text" name="bank_branch" id="bank_branch" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setBankBranch(e.target.value)} />
                  <label className="errorLabel">{bankBranchError}</label>
                </div>
                <div>
                  <label htmlFor="cc_img" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload cancelled cheque/passbook photo</label>
                  <input type="file" name="cc_img" id="cc_img" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => handleCcImgChange(e)} />
                  <label className="errorLabel">{ccImgError}</label>
                </div>

                <div>
                  <label htmlFor="referral_code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter referral code</label>
                  <input type="text" name="referral_code" id="referral_code" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    value={referral_code}
                    required=""
                    onChange={(e) => setReferralCode(e.target.value)} />
                  <label className="errorLabel">{referralCodeError}</label>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input id="accept" aria-describedby="accept" type="checkbox" name="atcnp" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required=""
                        onChange={(e) => setAtcnp(e.target.checked)} />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="accept" className="text-gray-500 dark:text-gray-300">I have read and accepted all the terms and conditions.</label>
                    </div>
                  </div>
                  <label className="errorLabel">{atcnpError}</label>
                </div>
                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign Up</button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account <Link to='/login' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Click here to Login</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Signup