import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import apiRoutes from '../components/common/apiRoutes.json';

function UserProfile() {

  const [userData, setUserData] = useState({})
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    setApiError("")

    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };
    axios.post(apiRoutes.customer + '/find', { "customerid": 1 }, { headers })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status) {
            setUserData(response.data.data)
            localStorage.setItem('status', response.data.data.status);
            localStorage.setItem('name', response.data.data.name);
            localStorage.setItem('referralcode', response.data.data.referral_code);
            localStorage.setItem('userpic', response.data.data.customer_img || 'noimage.png');
          } else {
            setApiError(response.data.msg)
          }
        }
      })
      .catch(err => console.log(err))

  }, [])

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                User Profile Details
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6">
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User full name: {userData?.name}</label>

                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User email: {userData?.email}</label>

                </div>
                <div>
                  <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User mobile number: {userData?.mobile}</label>

                </div>
                <div>
                  <label htmlFor="adhar_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User adhar card number: {userData?.adhar_card_no}</label>

                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adhar card front side: </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/adhar/${userData?.adhar_img_f || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adhar card back side: </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/adhar/${userData?.adhar_img_b || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User pan number: {userData?.pan_card_no}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pan card : </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/pan/${userData?.pan_img || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User bank name: {userData?.bank_name}</label>
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User bank account number: {userData?.bank_acc_no}</label>
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User bank ifsc number: {userData?.bank_ifsc_no}</label>
                </div>
                <div>
                  <label htmlFor="pan_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User bank branch: {userData?.bank_branch}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cancelled cheque/ Passbook : </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/cheque/${userData?.cc_img || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="profession" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User verification status: {userData?.status}</label>
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer photo : </label>
                  <img className="docImg" src={`${apiRoutes.imagePath}/profilepic/${userData?.customer_img || 'noimage.png'}`} alt="" />
                </div>
                <div>
                  <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer current profile type : {userData?.profile_type}</label>
                </div>
                <div>
                  <label htmlFor="referral_code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer referral code : <a href={`whatsapp://send?text=Please use my referral code: ${userData?.referral_code} while registering on web.hubertonfintech.in/Signup?rc=${userData?.referral_code}`} data-action="share/whatsapp/share" target="_blank" rel="noreferrer">{userData?.referral_code}</a></label>
                </div>
                <div>
                  <label htmlFor="agreement_doc" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer agreement: </label>
                  {userData?.agreement_doc ? <button className='bg-blue-600 p-3 text-white rounded-md' type='button'>
                    <Link to={`${apiRoutes.imagePath}/agreements/${userData?.agreement_doc}`} target="_blank" rel="noreferrer" style={{ 'color': 'white' }}>Download agreement</Link>
                  </button> : "Agreement not found!"}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default UserProfile