import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import Layout from './components/shared/Layout'
import Support from './pages/Support'
import Dashboard from './pages/Dashboard'
import Reports from './pages/Reports'
import Documents from './pages/Documents'
import Login from './components/Auth/Login'
import Signup from './components/Auth/Signup'
import ForgotPass from './components/Auth/ForgotPass'
import InvestmentHistory from './pages/InvestmentHistory'
import WithdrawalHistory from './pages/WithdrawalHistory'
import AddWithdrawalRequest from './pages/AddWithdrawalRequest'
import AddInvestmentRequest from './pages/AddInvestmentRequest'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import UserProfile from './pages/UserProfile'
import ChangePassword from './pages/ChangePassword'
import ReferralCode from './pages/ReferralCode'
import ReUploadDocs from './pages/ReUploadDocs'

const PrivateRoute = ({ element }) => {
    const isToken = localStorage.getItem('token');
    if (isToken) {
        return element;
    } else {
        return <Navigate to="/login" />;
    }
};

const PublicRoute = ({ element }) => {
    const isToken = localStorage.getItem('token');
    if (isToken === null) {
        return element;
    } else {
        return <Navigate to="/dashboard" />;
    }
};

function App() {
    return (
        <Router>
            <Routes>
                <Route index element={<PublicRoute element={<Login />} />} />
                {/* <Route index element={<Login />} />
                <Route path="login" element={<Login />} />PublicRoute */}
                <Route path="login" element={<PublicRoute element={<Login />} />} />
                <Route path="signup" element={<PublicRoute element={<Signup />} />} />
                <Route path="forgot-password" element={<PublicRoute element={<ForgotPass />} />} />
                {/* <Route path="/" element={<Layout />}> */}
                <Route path="/" element={<PrivateRoute element={<Layout />} />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    {/* <Route path="dashboard" element={<PrivateRoute element={<Dashboard />} />} /> */}
                    <Route path="reports" element={<Reports />} />
                    <Route path="documents" element={<Documents />} />
                    <Route path="investment-history" element={<InvestmentHistory />} />
                    <Route path="withdrawal-history" element={<WithdrawalHistory />} />
                    <Route path="withdrawal-request" element={<AddWithdrawalRequest />} />
                    <Route path="investment-request" element={<AddInvestmentRequest />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="profile" element={<UserProfile />} />
                    <Route path="change-password" element={<ChangePassword />} />
                    <Route path="referral-code" element={<ReferralCode />} />
                    <Route path="upload" element={<ReUploadDocs />} />
                    <Route path="support" element={<Support />} />
                </Route>
                {/* <Route path="*" element={<H1> Page not found!!! </H1>} /> */}
            </Routes>
        </Router>
    )
}

export default App
