import React from 'react'
import apiRoutes from '../components/common/apiRoutes.json';
import { Link } from 'react-router-dom'

function Documents() {
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center">

        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Download agreements and documents
            </h1>
            <div>
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Document 1 </label>
              <button className='bg-blue-600 p-3 text-white rounded-md' type='button'>
                <Link to={`${apiRoutes.imagePath}/downloads/sample1.pdf`} target="_blank" rel="noreferrer" style={{ 'color': 'white' }}>click to download</Link>
              </button>
            </div>

            <div>
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Document 2 </label>
              <button className='bg-blue-600 p-3 text-white rounded-md' type='button'>
                <Link to={`${apiRoutes.imagePath}/downloads/sample2.pdf`} target="_blank" rel="noreferrer" style={{ 'color': 'white' }}>click to download</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Documents