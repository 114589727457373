import React from 'react'
import WithdrawalTransactions from '../components/WithdrawalTransactions'
import { Link } from 'react-router-dom'

function WithdrawalHistory() {
  return (
    <div>
      <div></div>
      <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center" style={{'justifyContent': 'flex-end'}}>
          <div className="pl-4 pr-4">
            <button className='withdrawalbtn bg-red-600 p-3 text-white rounded-md' type='button'>
              <Link to='/withdrawal-request' style={{ 'color': 'white' }}>Raise Withdrawal Request</Link>
            </button>
          </div>
        </div>
      <div className="flex flex-row gap-4 w-full">
        <WithdrawalTransactions />
      </div>
    </div>
  )
}

export default WithdrawalHistory