import React, { useState } from 'react'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';
// import { columns, rdata } from '../components/common/data';
// import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
// import ReactDOM from "react-dom";
import BuyerProfilePieChart from '../components/BuyerProfilePieChart'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { toCurrencyFormat, toFullDateFormat } from '../components/common/commonLogic';

function Reports() {
  const filterCondition = { filter: true, filterParams: { filterOptions: ['contains', 'startsWith'], trimInput: true, maxNumConditions: 1 } }
  const MAX_REPORT_DURATION = 547;

  const [data, setData] = useState([]);
  const [colDefs, setColDefs] = useState([
    { headerName: "Sr. No.", cellRenderer: params => { return Number(params.node.id) + 1; }, width: 80 },
    { field: "transaction_id", headerName: "Transaction ID/No.", ...filterCondition },
    { field: "investment_date", headerName: "Transaction Date", ...filterCondition },
    { field: "amount", valueFormatter: params => { return toCurrencyFormat(params?.value || 0) }, ...filterCondition },
    { field: "transaction_type", headerName: "Transaction Type", ...filterCondition },
    { field: "payment_status", headerName: "Transaction Status", ...filterCondition },
    { field: "createdat", headerName: "Request Added Date", valueFormatter: params => { return new Date(params.value).toLocaleString() } },
    { field: "updatedat", headerName: "Last Updated Date", valueFormatter: params => { return new Date(params.value).toLocaleString() } },
    { field: "remark", headerName: "Remark/Note", ...filterCondition }
  ]);
  const [report_name, setReportName] = useState('Investment Report');
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();

  const [reportNameError, setReportNameError] = useState();
  const [startDateError, setStartDateError] = useState();
  const [endDateError, setEndDateError] = useState();
  const [apiError, setApiError] = useState("");

  const reportNames = [
    'Investment Report',
    'Withdrawal Report',
    //'Profit Report'
  ]
  const graphData = [
    { name: 'Profit', value: 1540 },
    { name: 'Investment', value: 1620 },
    { name: 'Withdrawal', value: 1210 }
  ]

  const tableColumn = {
    'Withdrawal Report': [
      { headerName: "Sr. No.", cellRenderer: params => { return Number(params.node.id) + 1; }, width: 80 },
      { field: "transaction_id", headerName: "Transaction ID/No.", ...filterCondition },
      { field: "withdrawal_date", headerName: "Transaction Date", ...filterCondition },
      { field: "amount", valueFormatter: params => { return toCurrencyFormat(params?.value || 0) }, ...filterCondition },
      { field: "payment_status", headerName: "Transaction Status", ...filterCondition },
      { field: "createdat", headerName: "Request Added Date", valueFormatter: params => { return new Date(params.value).toLocaleString() } },
      { field: "updatedat", headerName: "Last Updated Date", valueFormatter: params => { return new Date(params.value).toLocaleString() } },
      { field: "remark", headerName: "Remark/Note", ...filterCondition }
    ],
    'Investment Report': [
      { headerName: "Sr. No.", cellRenderer: params => { return Number(params.node.id) + 1; }, width: 80 },
      { field: "transaction_id", headerName: "Transaction ID/No.", ...filterCondition },
      { field: "investment_date", headerName: "Transaction Date", ...filterCondition },
      { field: "amount", valueFormatter: params => { return toCurrencyFormat(params?.value || 0) }, ...filterCondition },
      { field: "transaction_type", headerName: "Transaction Type", ...filterCondition },
      { field: "upi_id", headerName: "UPI ID", ...filterCondition },
      { field: "payment_status", headerName: "Transaction Status", ...filterCondition },
      { field: "createdat", headerName: "Request Added Date", valueFormatter: params => { return toFullDateFormat(params.value) } },
      { field: "updatedat", headerName: "Last Updated Date", valueFormatter: params => { return toFullDateFormat(params.value) } },
      { field: "remark", headerName: "Remark/Note", ...filterCondition }
    ],
    'Profit Report': [
      { headerName: "Sr. No.", cellRenderer: params => { return Number(params.node.id) + 1; }, width: 80 },
      { field: "date", headerName: "Transaction Date", ...filterCondition, width: 160 },
      { field: "principal_amount", headerName: "Principal Amount", valueFormatter: params => { return toCurrencyFormat(params?.value || 0) }, ...filterCondition, width: 200 },
      { field: "principal_profit_amount", headerName: "Profit on Principal Amount", valueFormatter: params => { return toCurrencyFormat(params?.value || 0) }, ...filterCondition, width: 230 },
      { field: "referral_principal_amount", headerName: "Referral Principal Amount", valueFormatter: params => { return toCurrencyFormat(params?.value || 0) }, ...filterCondition, width: 230 },
      { field: "referral_principal_profit", headerName: "Profit on Referral Principal Amount", valueFormatter: params => { return toCurrencyFormat(params?.value || 0) }, ...filterCondition, width: 280 },
      { field: "total_profit", headerName: "Total Profit", valueFormatter: params => { return toCurrencyFormat(params?.value || 0) }, ...filterCondition }
    ]
  }

  const handleReportSubmit = (e) => {
    e.preventDefault();
    let isValidCred = true;
    setReportNameError('')
    setStartDateError('')
    setEndDateError('')
    setApiError('')

    const today = new Date();
    const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
    //console.log("== todayDate ==", todayDate)

    //console.log("== report_name ==", report_name)
    if (report_name === undefined || "" === report_name) {
      setReportNameError("Please select your bank from list")
      isValidCred = false;
      return
    }
    if (start_date === undefined || "" === start_date) {
      setStartDateError("Please select report start date")
      isValidCred = false;
      return
    }
    //console.log("== start_date ==", start_date)

    if (start_date && start_date > todayDate) {
      setStartDateError("The start date is not greater than today's date")
      isValidCred = false;
      return
    }

    if (end_date === undefined || "" === end_date) {
      setEndDateError("Please select report end date")
      isValidCred = false;
      return
    }
    //console.log("== end_date ==", end_date)

    if (end_date && end_date > todayDate) {
      setEndDateError("The end date is not greater than today's date")
      isValidCred = false;
      return
    }

    if (start_date && end_date && end_date < start_date) {
      setEndDateError("The end date must be greater than start date")
      isValidCred = false;
      return
    }

    if (isValidCred && start_date && end_date) {
      let diff = new Date(end_date).getTime() - new Date(start_date).getTime();
      let daydiff = diff / (1000 * 60 * 60 * 24);
      if (daydiff > MAX_REPORT_DURATION) {
        setEndDateError("The maximum allowed report duration is 18 months only. Please reduce durations!")
        isValidCred = false;
        return
      }
    }

    if (isValidCred) {
      setColDefs(tableColumn[report_name])
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };

      axios.post(apiRoutes.report, {
        'report_name': report_name,
        'start_date': start_date,
        'end_date': end_date
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              setData(response.data.data)
              if (response.data.data.length === 0) { alert("No record found!") }
            } else {
              setApiError(response.data.msg)
              setData([])
            }
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Reports
            </h1>
            <p className="errorLabel">{apiError}</p>
            <form className="space-y-4 md:space-y-6" onSubmit={handleReportSubmit}>
              <div>
                <label htmlFor="report_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select report name</label>
                <select name="report_name" id="report_name" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                  onChange={(e) => setReportName(e.target.value)} >
                  {reportNames.map((report) => (
                    <option key={report} value={report}>
                      {report}
                    </option>
                  ))}
                </select>
                <label className="errorLabel">{reportNameError}</label>
              </div>

              <div>
                <label htmlFor="start_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select report start date*</label>
                <input type="date" name="start_date" id="start_date" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => setStartDate(e.target.value)} />
                <label className="errorLabel">{startDateError}</label>
              </div>

              <div>
                <label htmlFor="end_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select report end date*</label>
                <input type="date" name="end_date" id="end_date" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                  onChange={(e) => setEndDate(e.target.value)} />
                <label className="errorLabel">{endDateError}</label>
              </div>

              <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Generate Report</button>
            </form>
          </div>
        </div>
      </section>

      {data && data.length > 0 ? <section className="bg-gray-50 dark:bg-gray-900">
        <div className="ag-theme-quartz" style={{ height: 500 }}>
          <AgGridReact rowData={data} columnDefs={colDefs}
            pagination={true} />
        </div>
        {/* <div className="flex flex-row gap-4 w-full"> <BuyerProfilePieChart graphData={graphData} /> </div> */}
      </section> : ""}
    </>
  )
}

export default Reports