import axios from 'axios'
import apiRoutes from './common/apiRoutes.json';
import React, { useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toCurrencyFormat } from '../components/common/commonLogic';

export default function WithdrawalTransactions() {
	const [data, setData] = useState([])
	const withdrawalTypes = {'principal_profit_amount': 'Principal Profit Amount', 'referral_principal_profit': 'Referral Principal Profit', 'principal_amount': 'Principal Amount' }

	useEffect(() => {
		const headers = {
			'Content-Type': 'application/json',
			'token': localStorage.getItem('token'),
		};

		axios
			.get(apiRoutes.withdrawal + '/list', { headers })
			.then((response) => {
				if (response.status === 200) {
					if (response.data.status) {
						setData(response.data.data)
					}
				}
			})
	}, [])

	const confirmRequest = (id) => {
		confirmAlert({
			title: 'Are you sure to cancel this Request!',
			buttons: [
				{
					label: 'Yes',
					onClick: () => cancelRequest(id)
				},
				{
					label: 'No'
				}
			]
		});
	}

	const cancelRequest = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			'token': localStorage.getItem('token'),
		};

		axios
			.put(apiRoutes.withdrawal + '/cancel', {
				'withdrawal_id': id
			}, { headers })
			.then((response) => {
				if (response.status === 200) {
					if (response.data.status) {
						setData(data.map(req => {
							if (req._id === id) {
								req.payment_status = "Cancelled";
								req.remark = "Request cancelled by user.";
							}
							return req;
						}))
					}
				}
			})
	}

	return (
		<div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
			<strong className="text-gray-700 font-medium">Recent Withdrawal Transactions</strong>
			<div className="border-x border-gray-200 rounded-sm mt-3">
				<table className="w-full text-gray-700">
					<thead>
						<tr>
							<th>Sr. No.</th>
							<th>Transaction ID</th>
							<th>Withdrawal Date</th>
							<th>Withdrawal Amount</th>
							<th>Withdrawal Status</th>
							<th>Withdrawal From</th>
							<th>Remark</th>
							<th>Cancel Request</th>
						</tr>
					</thead>
					<tbody>
						{data.map((withdrawal, index) => (
							<tr key={withdrawal._id}>
								<td> {index + 1}] </td>
								<td> {withdrawal.transaction_id} </td>
								<td> {withdrawal.withdrawal_date} </td>
								<td> {toCurrencyFormat(withdrawal.amount)} </td>
								<td> {withdrawal.payment_status} </td>
								<td> {withdrawal.withdrawal_type ? withdrawalTypes[withdrawal.withdrawal_type] : ""} </td>
								<td> {withdrawal.remark} </td>
								<td> {withdrawal.payment_status === 'Pending' ? <button className='withdrawlbtn bg-red-600 p-3 text-white rounded-md' onClick={() => confirmRequest(`${withdrawal._id}`)} type='button'>Cancel Request</button> : ""} </td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}
