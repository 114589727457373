import React from 'react'

export default function Support() {
	return (
		<section className="bg-gray-50 dark:bg-gray-900">
			<div className="flex flex-col items-center justify-center">

				<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
							Customer Support
						</h1>
						<div>
							<label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Contact number: 7058666404</label>
							{/* <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Contact number: 98XXXXXXX33</label> */}
						</div>
						<div>
							<label htmlFor="name2" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Email address: support@hubertonfintech.in</label>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}