import React, { useState, useEffect } from 'react'
import apiRoutes from './common/apiRoutes.json';
// import { IoBagHandle, IoPieChart, IoPeople, IoCart } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import axios from 'axios'
// import BuyerProfilePieChart from '../components/BuyerProfilePieChart'
import { toCurrencyFormat, toDateFormat } from '../components/common/commonLogic';


export default function DashboardStatsGrid() {

	const [data, setData] = useState({})
	// const [graphData, setGraphData] = useState([])
	const [displayMsg, setDisplayMsg] = useState("")

	useEffect(() => {
		const headers = {
			'Content-Type': 'application/json',
			'token': localStorage.getItem('token'),
		};

		axios
			.get(apiRoutes.dashboard, { headers })
			.then((response) => {
				if (response.status === 200) {
					if (response.data.status) {
						setData(response.data.data[0] || {})
						// let temdata = [
						// 	{ name: 'Principal amount', value: response.data.data[0].principal_amount },
						// 	{ name: 'Total profit', value: response.data.data[0].total_profit },
						// 	{ name: 'Total withdrawal', value: response.data.data[0].total_withdrawal }
						// ]
						// setGraphData(temdata)
						// console.log("== graphData ==", graphData)
					} else {
						if (response.data.verification) {
							setDisplayMsg(response.data.msg)
						}
					}
				}
			})
	}, [])

	return (
		<div className="flex gap-4" style={{ 'flexWrap': 'wrap' }}>
			{displayMsg !== "" ? (
				<BoxWrapper>
					{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
					<IoBagHandle className="text-2xl text-white" />
				</div> */}
					<div className="pl-4">
						<span className="text-sm text-gray-500 font-light"></span>
						<div className="flex items-center">
							<strong className="text-xl text-gray-700 font-semibold">{displayMsg}</strong>
							<strong className="text-xl text-gray-700 font-semibold"> </strong>
							<br />
						</div>
						<div className="flex items-center">
							<br />
							<strong className="text-xl text-gray-700 font-semibold"> </strong>
							<strong className="text-xl text-gray-700 font-semibold">Please login back after your account gets activated!!!</strong>
							<br />
						</div>
					</div>
				</BoxWrapper>
			) : (

				<div>
					<div className="flex gap-4" style={{ 'flexWrap': 'wrap' }}>
						<BoxWrapper>
							{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
					<IoBagHandle className="text-2xl text-white" />
				</div> */}
							<div className="pl-4">
								<span className="text-sm text-gray-500 font-light">Your Principal Amount</span>
								<div className="flex items-center">
									<strong className="text-xl text-gray-700 font-semibold">{toCurrencyFormat(data?.principal_amount || 0)}</strong>
									{/* <span className="text-sm text-green-500 pl-2">+343</span> */}
								</div>
							</div>
						</BoxWrapper>
						<BoxWrapper>
							{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
					<IoPieChart className="text-2xl text-white" />
				</div> */}
							<div className="pl-4">
								<span className="text-sm text-gray-500 font-light">Referral's Principal Amount</span>
								<div className="flex items-center">
									<strong className="text-xl text-gray-700 font-semibold">{toCurrencyFormat(data?.referral_principal_amount || 0)}</strong>
									{/* <span className="text-sm text-green-500 pl-2">-343</span> */}
								</div>
							</div>
						</BoxWrapper>
						<BoxWrapper>
							{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoPeople className="text-2xl text-white" />
				</div> */}
							<div className="pl-4">
								<span className="text-sm text-gray-500 font-light">Profit on Your Principal Amount</span>
								<div className="flex items-center">
									<strong className="text-xl text-gray-700 font-semibold">{toCurrencyFormat(data?.principal_profit_amount || 0)}</strong>
									{/* <span className="text-sm text-red-500 pl-2">3000</span> */}
								</div>
							</div>
						</BoxWrapper>
						<BoxWrapper>
							{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoPeople className="text-2xl text-white" />
				</div> */}
							<div className="pl-4">
								<span className="text-sm text-gray-500 font-light">Profit on Referral's Principal Amount</span>
								<div className="flex items-center">
									<strong className="text-xl text-gray-700 font-semibold">{toCurrencyFormat(data?.referral_principal_profit || 0)}</strong>
									{/* <span className="text-sm text-red-500 pl-2">3000</span> */}
								</div>
							</div>
						</BoxWrapper>
						<BoxWrapper>
							{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoPeople className="text-2xl text-white" />
				</div> */}
							<div className="pl-4">
								<span className="text-sm text-gray-500 font-light">Total Profit Amount</span>
								<div className="flex items-center">
									<strong className="text-xl text-gray-700 font-semibold">{toCurrencyFormat(data?.total_profit || 0)}</strong>
									{/* <span className="text-sm text-red-500 pl-2">3000</span> */}
								</div>
							</div>
						</BoxWrapper>
						<BoxWrapper>
							{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoPeople className="text-2xl text-white" />
				</div> */}
							<div className="pl-4">
								<span className="text-sm text-gray-500 font-light">Total Withdrawal Amount</span>
								<div className="flex items-center">
									<strong className="text-xl text-gray-700 font-semibold">{toCurrencyFormat(data?.total_withdrawal || 0)}</strong>
									{/* <span className="text-sm text-red-500 pl-2">3000</span> */}
								</div>
							</div>
						</BoxWrapper>
						<BoxWrapper>
							{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoPeople className="text-2xl text-white" />
				</div> */}
							<div className="pl-4">
								<span className="text-sm text-gray-500 font-light">Net Invested Amount</span>
								<div className="flex items-center">
									<strong className="text-xl text-gray-700 font-semibold">{toCurrencyFormat(data?.net_amount || 0)}</strong>
									{/* <span className="text-sm text-red-500 pl-2">3000</span> */}
								</div>
							</div>
						</BoxWrapper>
						<BoxWrapper>
							{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoPeople className="text-2xl text-white" />
				</div> */}
							<div className="pl-4">
								<span className="text-sm text-gray-500 font-light">Last Portfolio Updated On:</span>
								<div className="flex items-center">
									<strong className="text-xl text-gray-700 font-semibold">{data?.updatedat ? toDateFormat(data?.updatedat) : "-"}</strong>
									{/* <span className="text-sm text-red-500 pl-2">3000</span> */}
								</div>
							</div>
						</BoxWrapper>
						<BoxWrapper>
							<div className="pl-4 pr-4">
								<button className='withdrawlbtn bg-red-600 p-3 text-white rounded-md' type='button'>
									<Link to='/withdrawal-request' style={{ 'color': 'white' }}>Withdrawal Request</Link>
								</button>
							</div>
						</BoxWrapper>
					</div>
				</div>)}
			{/* {graphData.length > 0 ? <div className="flex flex-row gap-4 w-full"> <BuyerProfilePieChart graphData={graphData} /> </div> : ""} */}
		</div>
		// <div className="flex gap-4">
		// 	<BoxWrapper>
		// 		{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
		// 			<IoBagHandle className="text-2xl text-white" />
		// 		</div> */}
		// 		<div className="pl-4">
		// 			<span className="text-sm text-gray-500 font-light">Current investment</span>
		// 			<div className="flex items-center">
		// 				<strong className="text-xl text-gray-700 font-semibold">{data?.curr_investment}</strong>
		// 				{/* <span className="text-sm text-green-500 pl-2">+343</span> */}
		// 			</div>
		// 		</div>
		// 	</BoxWrapper>
		// 	<BoxWrapper>
		// 		{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
		// 			<IoPieChart className="text-2xl text-white" />
		// 		</div> */}
		// 		<div className="pl-4">
		// 			<span className="text-sm text-gray-500 font-light">Current Month Profit</span>
		// 			<div className="flex items-center">
		// 				<strong className="text-xl text-gray-700 font-semibold">Rs.{
		// 					Object.values(data?.weekly_profit || []).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
		// 				}</strong>
		// 				{/* <span className="text-sm text-green-500 pl-2">-343</span> */}
		// 			</div>
		// 		</div>
		// 	</BoxWrapper>
		// 	<BoxWrapper>
		// 		{/* <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
		// 			<IoPeople className="text-2xl text-white" />
		// 		</div> */}
		// 		<div className="pl-4">
		// 			<span className="text-sm text-gray-500 font-light">Overall Profit</span>
		// 			<div className="flex items-center">
		// 				<strong className="text-xl text-gray-700 font-semibold">Rs.{data?.total_profit}</strong>
		// 				{/* <span className="text-sm text-red-500 pl-2">3000</span> */}
		// 			</div>
		// 		</div>
		// 	</BoxWrapper>
		// 	<BoxWrapper>
		// 		<div className="pl-4 pr-4">
		// 			<button className='withdrawlbtn bg-red-600 p-3 text-white rounded-md' type='button'>
		// 				<Link to='/withdrawal-request' style={{'color':'white'}}>Withdrawal Request</Link>
		// 			</button>
		// 		</div>
		// 	</BoxWrapper>
		// </div>
	)
}

function BoxWrapper({ children }) {
	return <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">{children}</div>
}
