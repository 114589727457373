import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../components/common/apiRoutes.json';

function AddWithdrawalRequest() {
  const [amount, setAmount] = useState();
  const [type, setType] = useState("principal_profit_amount");

  const [amountError, setAmountError] = useState();
  const [typeError, setTypeError] = useState();

  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();

  const [data, setData] = useState({})

  const options = [
    { value: 'principal_profit_amount', label: 'Principal Profit Amount' },
    { value: 'referral_principal_profit', label: 'Referral Principal Profit' },
    { value: 'principal_amount', label: 'Principal Amount' }
  ];

  const profitData = { principal_profit_amount: 0, referral_principal_profit: 0, principal_amount: 0 }

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('token'),
    };

    axios
      .get(apiRoutes.dashboard, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            setData(response.data.data[0] || profitData)
          } else {
            if (response.data.verification) {
              setApiError(response.data.msg)
            }
          }
        }
      })
  }, [])

  const handleSubmit = (e) => {
    setAmountError('')
    setTypeError('')

    e.preventDefault();
    let isValidCred = true;

    if (type === undefined || "" === type) {
      setTypeError("Please select valid withdrawal type")
      isValidCred = false;
      return
    }
    if (amount === undefined || "" === amount || amount <= 0 || isNaN(amount)) {
      setAmountError("Please enter valid amount")
      isValidCred = false;
      return
    }

    if (amount && type) {
      if (data[type] === undefined || data[type] === "" || isNaN(data[type])) {
        setApiError("Error in getting your balance details. Please reload/refresh the page!")
        isValidCred = false;
        return
      } else if (data[type] && Number(amount) > Number(data[type])) {
        setAmountError("Amount is exceeded than your balance!")
        isValidCred = false;
        return
      }
    }

    if (isValidCred) {
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token'),
      };

      axios.post(apiRoutes.withdrawal + '/add', {
        'amount': amount,
        'type': type
      }, { headers })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status) {
              // const userData = response.data.data;
              navigate('/withdrawal-history')
            } else {
              setApiError(response.data.msg)
              if (response.data?.missingFields && response.data?.missingFields.length > 0) {
              }
            }
          }
        })
        .catch(err => console.log(err))
    }
  }


  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Add Withdrawal Request
              </h1>
              <p className="errorLabel">{apiError}</p>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div style={{ 'border': '2px solid blue', 'padding': '10px' }}>
                  <h4 className="text-s font-bold leading-tight tracking-tight text-gray-900 md:text-1xl dark:text-white"> Principal and profit amounts details: </h4>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Available Principal Amount: {data?.principal_amount || 0}</label>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Available Principal Profit Amount: {data?.principal_profit_amount || 0}</label>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Available Referral Principal Profit Amount: {data?.referral_principal_profit || 0}</label>
                </div>
                <div>
                  <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select withdrawal amount from*</label>
                  <select name="type" id="type" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                    onChange={(e) => setType(e.target.value)} >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <label className="errorLabel">{typeError}</label>
                </div>
                <div>
                  <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter withdrawal amount*</label>
                  <input type="number" name="amount" id="amount" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required=""
                    onChange={(e) => setAmount(e.target.value)} />
                  <label className="errorLabel">{amountError}</label>
                </div>

                <button type="submit" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit Request</button>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddWithdrawalRequest