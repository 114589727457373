import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import axios from 'axios'
// import apiRoutes from '../components/common/apiRoutes.json';

function ReferralCode() {

  // const [userData, setUserData] = useState({})
  const [refCode, setRefCode] = useState("")
  // const [apiError, setApiError] = useState("");

  useEffect(() => {
    // setApiError("")
    setRefCode(localStorage.getItem('referralcode'))
    // const headers = {
    //   'Content-Type': 'application/json',
    //   'token': localStorage.getItem('token'),
    // };
    // axios.post(apiRoutes.customer + '/find', { "customerid": 1 }, { headers })
    //   .then(response => {
    //     console.log(response)
    //     if (response.status === 200) {
    //       if (response.data.status) {
    //         setUserData(response.data.data)
    //       } else {
    //         setApiError(response.data.msg)
    //       }
    //     }
    //   })
    //   .catch(err => console.log(err))

  }, [])

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Your referral code details
              </h1>
              <form className="space-y-4 md:space-y-6">
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your referral code: <a href={`whatsapp://send?text=Please use my referral code: ${refCode} while registering on web.hubertonfintech.in/Signup?rc=${refCode}`} data-action="share/whatsapp/share" target="_blank" rel="noreferrer"> {refCode}</a>
                    {/* <button className='bg-blue-600 p-3 text-white rounded-md' type='button'>
                      <Link to={`whatsapp://send?text=Please use my referral code: ${refCode} while registering on hubertonfintech.in!`} data-action="share/whatsapp/share" target="_blank" rel="noreferrer" style={{ 'color': 'white' }}>Share referral code</Link>
                    </button> */}
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default ReferralCode