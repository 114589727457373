import React from 'react'

function RulesAndRegulations() {
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center w-full bg-white rounded-lg shadow dark:border ">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Rules and regulations
          </h1>
        </div>

        <div className="p-6 space-y-3 md:space-y-5 sm:p-6">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-l dark:text-white">
            Registration
          </h1>
          <ol style={{ 'margin': '10px', 'padding': '10px' }} type="1">
            <li>1] Client must be an Indian resident over 18 years of age and must possess valid KYC documents to register with us</li>
            <li>2] Client must complete KYC once registration is done to activate the account.</li>
            <li>3] Client may register with our partner's reference code/link.</li>
          </ol>
        </div>
        <div className="p-6 space-y-3 md:space-y-5 sm:p-6">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-l dark:text-white">
            KYC Guidelines
          </h1>
          <ol style={{ 'margin': '10px', 'padding': '10px' }} type="1">
            <li>Client must submit valid and clear KYC documents copies - <strong>Govt IDs - PAN Card and Aadhar Card Passport sized <br />
              photograph</strong> (other images will lead to KYC rejection)</li>
          </ol>
        </div>
        <div className="p-6 space-y-3 md:space-y-5 sm:p-6">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-l dark:text-white">
            Bank documents
          </h1>
          <ol style={{ 'margin': '10px', 'padding': '10px' }} type="1">
            <li>Cancelled Cheque ("Cancelled cheque" must be written) Passbook first page indicating all account details. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
          </ol>
        </div>
        <div className="p-6 space-y-3 md:space-y-5 sm:p-6">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-l dark:text-white">
            Deposit
          </h1>
          <ol style={{ 'margin': '10px', 'padding': '10px' }} type="1">
            <li>1] Client must deposit fund's only once KYC Approval is done. Any deposits made before KYC approval will  not be<br />included in the investment cycle.</li>
            <li>2] Minimum Deposit amount is 25,00,000/- Rs. INR.</li>
            <li>3] Client must only make deposits from the bank account which has been used for the completion of KYC.<br />
              <strong>Deposits made from any other account/s is strictly prohibited and will not get approved.</strong></li>
            <li>4] <strong>Cash Deposit requests will not be accepted.</strong></li>
            <li>5] Once the funds are deposited, the client needs to raise an <strong>'Add Investment'</strong> request under<br />
              <strong>'Investments History'</strong> with the <strong>Proof of transaction and its details</strong>. This needs to be done on priority once <br />
              payment is done to avoid any delays in the investment cycle. Once a deposit is approved by the company, the <br />
              deposit amount will reflect in the holding balance of the client's account and will <strong>take up to 2 days</strong> to process <br />
              post which the deposit amount will start reflecting in the Investing balance.</li>
          </ol>
        </div>
        <div className="p-6 space-y-3 md:space-y-5 sm:p-6">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-l dark:text-white">
            Profit Withdrawal
          </h1>
          <ol style={{ 'margin': '10px', 'padding': '10px' }} type="1">
            <li>1] PnL (Profit and Loss) will be updated in the client's dashboard account.</li>
            <li>2] Profit withdrawal amount may take up to 07 working days (not including holidays) to be credited to the client's<br />bank
              account once a withdrawal request has been approved.</li>
            <li>3] The profit withdrawal request is permitted after 31 days from the date of deposit confirmation and the previous<br />
              profit withdrawal request date. </li>
          </ol>
        </div>
        <div className="p-6 space-y-3 md:space-y-5 sm:p-6">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-l dark:text-white">
            Principal amount withdrawal
          </h1>
          <ol style={{ 'margin': '10px', 'padding': '10px' }} type="1">
            <li>1] Principal amount redemption request is permitted only after 31 financial days from the Deposit confirmation<br />
              date. Also, the deposited amount will be credited to a bank account after 31+2 financial days from the date of the<br />
              principal amount redemption request (These days cannot fall on holidays and weekends).</li>
            <li>2] Deposited amount withdrawal will be processed via CRM based on the Deposited amount withdrawal request.</li>
            <li>3] All deposit withdrawal requests should be made via the CRM. No withdrawals shall be processed on<br /> offline/verbal requests.</li>
            <li>4] If principal amount is withdraw then client can not get profit that month</li>
            <li>5] <strong>Note: Please read all the T&C before investing. We do not offer any fixed or guaranteed<br />returns on client investments.</strong></li>
          </ol>
        </div>


        <div>
        </div>
      </div>
    </section>
  )
}

export default RulesAndRegulations